<template>
  <div v-if="isAccessDenied"
    class="main-content">
    <h4>{{ accessDenied }}</h4>
  </div>
  <div v-else
    class="main-content">
    <div class="text-align-center">
      <h2 class="mb-2">
        {{ $t('menu.company-profile') }}
      </h2>
    </div>
    <div v-loading="savingForm"
      :element-loading-text="$t('general.saving')">
      <el-form ref="companyForm"
        :model="companyForm"
        :rules="companyRules"
        label-width="250px"
        class="show-non-editable m-auto max-width-600px">
        <el-form-item prop="tradingName"
          :label="$t('company.company-trading-name')">
          <el-input v-model="companyForm.tradingName"
            data-demo="Example Company Trading Name"
            name="tradingName"
            :disabled="!canEditCompany" />
        </el-form-item>
        <p v-if="isPublic && canEditCompany"
          class="text-align-center">
          {{ $t('authentication.setting-company') }}
        </p>
      </el-form>
      <div class="text-align-right">
        <el-button v-if="canViewBillingMarkets"
          data-name="view-company-markets"
          @click="$router.push('/company/markets', () => {})">
          {{ $t('company.view-markets') }}
        </el-button>
        <el-button v-if="canEditCompany"
          @click="initForm">
          {{ $t('general.revert') }}
        </el-button>
        <el-button v-if="canEditCompany"
          type="primary"
          data-name="save-company-markets"
          @click="saveChanges">
          {{ $t('general.save') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  inject: ['isFeatureEnabled'],

  data() {
    return {
      companyForm: {
        tradingName: '',
      },
      companyRules: {
        tradingName: { required: true, message: this.$t('validations.required', { thing: this.$t('company.company-trading-name') }), trigger: 'blur' },
      },
      savingForm: false,
    }
  },

  computed: {
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapState('Company', {
      company: state => state.data,
    }),
    ...mapGetters('Company', ['hasCompany']),
    ...mapGetters('Auth', ['hasAuth', 'isNewUser']),
    isAccessDenied() {
      return this.isFeatureEnabled('COMPANY_PROFILE_ENABLED') && this.isManagedContext
    },
    accessDenied() {
      const pageName = this.$t(this.$route.meta.title)
      return this.$t('general.feature-flag-access-error', { pageName })
    },
    canEditCompany() {
      return this.hasAuth('company_admin') || this.isNewUser
    },
    canViewBillingMarkets() {
      return this.hasCompany
          && this.isFeatureEnabled('PAYS_BILL')
          && this.hasAuth('financials')
          && !this.isManagedContext
    },
    isPublic() {
      return !this.hasCompany
    },
  },

  watch: {
    company(newValue) {
      this.companyForm.tradingName = newValue.tradingName
    },
  },

  mounted() {
    this.initForm()
  },

  methods: {
    ...mapActions('Auth', ['logout']),
    ...mapActions('Company', ['postCompany']),
    initForm() {
      this.companyForm.tradingName = this.company.tradingName
      if (this.$refs.companyForm) {
        this.$refs.companyForm.clearValidate()
      }
    },
    saveChanges() {
      this.$refs.companyForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }
        this.savingForm = true

        const saveData = { ...this.company }
        saveData.tradingName = this.companyForm.tradingName
        this.postCompany(saveData)
          .then(() => {
            this.savingForm = false
          })
          .catch(err => {
            console.error(err)
            this.savingForm = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.max-width-600px {
  max-width: 600px;
}
</style>
